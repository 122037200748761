<template>
  <section class="row">
    <div class="col" @click="focusInput">
      <div class="input-group" :class="{'invalid': !isAudienceValid}">

        <div class="row auto-suggest-header">
          <div class="col d-flex flex-row justify-content-end">
            <button type="button" class="tertiary" @click.prevent="clear">clear all</button>
          </div>
        </div>

        <div class="auto-suggest mb-2">
          <div
            class="auto-suggest-selection"
            v-for="(userId, index) in userIds"
            :key="userId"
            :class="{'invalid-email':!isAudienceValid
                && !allowExternalDomains
                && isExternalDomain(userById(userId).email)}"
          >
            <span v-if="userId !== loggedInUser.id">
              {{userNameById(userId) || userById(userId).email}}
            </span>
            <b v-else>You</b>
            <button type="button" @click="removeUser(index)" v-if="userId !== loggedInUser.id">
              <icon name="remove" height="7" width="7"/>
            </button>
          </div>

          <div
            class="auto-suggest-selection"
            v-for="(email, index) in emails"
            :key="email"
            :class="{'invalid-email':!isAudienceValid
                && !allowExternalDomains
                && isExternalDomain(email)}"
          >
            <span class="auto-suggest-text d-inline-block fs-block">{{email}}</span>
            <button type="button" @click="removeEmail(index)">
              <icon name="remove" height="7" width="7"/>
            </button>
          </div>

          <vue-autosuggest
            ref="autosuggest-user-input"
            class="d-flex flex-grow-1"
            :suggestions="filteredProfiles"
            :section-configs="sectionConfigs"
            :get-suggestion-value="getSuggestionValue"
            :input-props="{
              id: 'autosuggest-user-input',
              placeholder: 'Search by name or email',
            }"
            component-attr-class-autosuggest-results-container="autosuggest-results-container"
            @input="onInput"
            @selected="onSelectResult"
            @paste.prevent="onPaste"
            @keydown.enter.native="(e) => parseEmails(e.target.value)"
            >
            <template slot-scope="{suggestion}">
              <div v-if="suggestion.item.email">
                <span
                  v-if="userNameById(suggestion.item.userId)"
                  class="mr-5">
                  {{ userNameById(suggestion.item.userId) }}
                </span>
                <span>
                  {{ suggestion.item.email }}
                </span>
              </div>
              <div v-else data-tour="segment-users">
                <span>{{ suggestion.item.name }}</span>
                <small>{{ suggestion.item.users.length }} Users</small>
              </div>
            </template>
            <template slot="after-section-default">
              <i v-if="!filteredProfiles[0].data.length">No matching users found.</i>
            </template>
            <template slot="after-section-segments">
              <i v-if="!mySavedSegments.length">You don't have saved segments.</i>
            </template>
          </vue-autosuggest>
        </div>

        <Error v-if="!audienceHasMinRequired">
          <span>The audience requires at least {{ minAudience }} participants</span>
        </Error>
        <Error v-if="audienceHasExternalDomains && !this.allowExternalDomains">
          <span>All participants should have the following email domains:
            <em v-for="(d, i) in domains" :key="'domain-' + i">
              {{i === domains.length - 1 ? d + '.' : d + ','}}
            </em>
          </span>
        </Error>
        <span v-if="userLimit" class="user-count my-1" style="text-align: end">
          <b class="remaining" :class="{ 'remaining-urgent': remainingUsers <= 5 }">
            {{userCount}} PARTICIPANT{{ (userCount) === 1 ? '' : 'S' }} / {{remainingText}}
          </b>
          <p v-if="remainingUsers <= 0" class="user-count">
            Please email <b>
              <a href="mailto:success@balloon.app" target="_blank">
                success@balloon.app
              </a></b> if you need additional participants.
          </p>
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { VueAutosuggest } from 'vue-autosuggest';

export default {
  name: 'AudienceTypeahead',

  components: {
    VueAutosuggest,
  },

  props: {
    focusOnMount: {
      type: Boolean,
      required: false,
      default: true,
    },

    allowSavedSegments: { type: Boolean, default: false },
    allowExternalDomains: { type: Boolean, default: true },
    userLimit: { type: Number, default: 0 },

    emailsProp: {
      type: Array,
      required: false,
      default: () => [],
    },

    userIdsProp: {
      type: Array,
      required: false,
      default: () => [],
    },

    segmentIdsProp: {
      type: Array,
      required: false,
      default: () => [],
    },

    minAudience: {
      type: Number,
      default: 2,
    },
  },

  data() {
    return {
      segmentIds: [],
      emails: [],
      userIds: [],
      filteredProfiles: [{ data: [] }],

      sectionConfigs: {
        default: {
          limit: 6,
        },
        segments: {
          onSelected: ({ item }) => this.addUsersFromSegment(item),
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      profiles: 'allProfiles',
      userById: 'profileById',
      userNameById: 'userNameById',
      segmentById: 'segmentById',
      mySavedSegments: 'mySavedSegments',
      loggedInUser: 'loggedInUser',
      validateEmail: 'validateEmail',
      uniqueUserCount: 'uniqueUserCount',
      domains: 'allowedDomains',
      isExternalDomain: 'isExternalDomain',
    }),

    userCount() {
      return this.uniqueUserCount(this.userIds, this.emails, this.segmentIds);
    },

    remainingUsers() {
      return this.userLimit - this.userCount;
    },

    remainingText() {
      return `${this.remainingUsers} REMAINING`;
    },

    inputIsValidEmail() {
      return this.validateEmail(this.$refs['autosuggest-user-input'].internalValue);
    },

    audienceHasExternalDomains() {
      return this.userIds.some((userId) => this.isExternalDomain(this.userById(userId).email))
      || this.emails.some((email) => this.isExternalDomain(email));
    },

    audienceHasMinRequired() {
      return this.userIds.length + this.emails.length >= this.minAudience;
    },

    isAudienceValid() {
      if (!this.allowExternalDomains) {
        return this.audienceHasMinRequired && !this.audienceHasExternalDomains;
      }

      return this.audienceHasMinRequired;
    },
  },

  created() {
    this.setInitialValues();
    this.$emit('init', this.reset);
  },

  mounted() {
    if (this.focusOnMount) {
      this.$nextTick(() => this.focusInput());
    }
  },

  watch: {
    allowExternalDomains() { this.emitUpdate(); },
  },

  methods: {
    setInitialValues() {
      this.initialValues = {
        segmentIds: [...this.segmentIdsProp],
        emails: [...this.emailsProp],
        userIds: [...this.userIdsProp],
      };

      this.segmentIds = [...this.segmentIdsProp];
      this.emails = [...this.emailsProp];
      this.userIds = [...this.userIdsProp];
      this.filteredProfiles = [{ data: [] }];
      this.emitUpdate();
    },

    reset() {
      this.clear({
        segmentIds: [...this.initialValues.segmentIds],
        emails: [...this.initialValues.emails],
        userIds: [...this.initialValues.userIds],
      });
    },

    clear({ segmentIds = [], emails = [], userIds }) {
      this.segmentIds = segmentIds;
      this.emails = emails;
      this.userIds = userIds || [this.loggedInUser.id];

      this.filteredProfiles = [{ data: [] }];
      this.emitUpdate();
    },

    emitUpdate() {
      this.$emit('audience-updated', {
        segmentIds: this.segmentIds,
        userIds: this.userIds,
        emails: this.emails,
      });

      this.$emit('validate', this.isAudienceValid);
    },

    focusInput() {
      this.$refs['autosuggest-user-input'].$el.children[0].focus();
    },

    getSuggestionValue(suggestion) {
      return this.userNameById(suggestion.item.userId);
    },

    onSelectResult(item) {
      if (item) {
        this.addProfile(item.item);
      } else if (this.inputIsValidEmail) {
        const email = this.$refs['autosuggest-user-input'].internalValue.toLowerCase();
        this.addEmail(email);
      } else {
        return;
      }

      this.$refs['autosuggest-user-input'].internalValue = '';
      this.focusInput();
      this.setSuggestedProfiles({ users: [], segments: [] });
    },

    onInput(text) {
      if (!text) {
        return;
      }

      if (text.match(/[ ,;]/gi)) {
        this.parseEmails(text);
        return;
      }

      const query = text.toLowerCase();

      const users = this.profiles
        .filter((profile) => profile.teamStatus !== 'deactivated')
        .filter((profile) => !this.userIds.includes(profile.userId))
        .filter((profile) => profile.userId !== this.loggedInUser.id)
        .filter((profile) => (!this.allowExternalDomains
          ? !this.isExternalDomain(profile.email)
          : true))
        .filter((profile) => Object.keys(profile).some((key) => String(profile[key])
          .toLowerCase()
          .includes(query)))
        .slice(0, 8);

      const segments = this.mySavedSegments
        .filter((segment) => segment.status !== 'deactivated')
        .filter((segment) => segment.name.toLowerCase().includes(query))
        .slice(0, 8);

      this.setSuggestedProfiles({ users, segments });
    },

    onPaste(event) {
      const clipboardData = event.clipboardData || window.clipboardData;
      const text = clipboardData.getData('Text');
      this.parseEmails(text);
    },

    parseEmails(text) {
      const emailsArr = text.match(/([a-zA-Z0-9._'‘’+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);

      if (!emailsArr || !emailsArr.length) {
        return;
      }

      emailsArr.forEach((email) => {
        if (this.userLimit && this.remainingUsers <= 0) {
          this.$store.dispatch('errorToast', 'User not added! The participant limit has been reached.');
          return;
        }

        if (!this.allowExternalDomains && this.isExternalDomain(email)) {
          this.$store.dispatch('errorToast', 'Email not added! Its domain does not match your team\'s allowed domains.');
          return;
        }

        const validEmail = this.replaceInvalidCharacters(email);
        this.addEmail(validEmail);
      });

      const lastEmail = emailsArr[emailsArr.length - 1];
      const newText = text
        .slice(text.lastIndexOf(lastEmail) + lastEmail.length)
        .replace(/^[ ,;]*/, '');

      this.$nextTick(() => {
        this.$refs['autosuggest-user-input'].internalValue = newText;
      });

      this.onInput(newText);
      this.focusInput();
    },

    setSuggestedProfiles({ users, segments }) {
      this.filteredProfiles = [
        {
          data: users,
          name: 'default',
          label: 'Suggested Users',
        },
        {
          data: segments,
          name: 'segments',
          label: 'Select all users from a saved segment',
        },
      ];
    },

    addProfile(profile) {
      if (profile.teamStatus === 'deactivated') {
        this.$store.dispatch('errorToast', 'User has been deactivated!');
        return;
      }

      if (this.userLimit && this.remainingUsers <= 0) {
        this.$store.dispatch('errorToast', 'User not added! The participant limit has been reached.');
        return;
      }

      const { userId } = profile;
      if (!this.userIds.includes(userId)) {
        this.userIds.push(userId);
        this.emitUpdate();
        return;
      }

      this.$store.dispatch('errorToast', 'User already added!');
    },

    replaceInvalidCharacters(str) {
      return str.replace(/‘|’/gi, "'");
    },

    addEmail(value) {
      const email = value.toLowerCase();
      const profile = this.profiles.find((p) => p.email === email);

      if (profile) {
        this.addProfile(profile);
      } else if (!this.emails.includes(email)) {
        if (this.userLimit && this.remainingUsers <= 0) {
          this.$store.dispatch('errorToast', 'User not added! The participant limit has been reached.');
          return;
        }
        this.emails.push(email);
        this.emitUpdate();
      } else {
        this.$store.dispatch('errorToast', 'Email already added!');
      }
    },

    addUsersFromSegment(segment) {
      const updatedUserIds = [...new Set([...this.userIds, ...this.segmentById(segment.id).users])];
      const updatedUserCount = this.uniqueUserCount(updatedUserIds, this.emails, this.segmentIds);
      if (this.userLimit && updatedUserCount > this.userLimit) {
        this.$store.dispatch('errorToast', 'Segment not added! The participant limit has been reached.');
        return;
      }
      this.userIds = updatedUserIds;
      this.emitUpdate();
      this.focusInput();
    },

    removeUser(index) {
      this.userIds.splice(index, 1);
      this.emitUpdate();
    },

    removeEmail(index) {
      this.emails.splice(index, 1);
      this.emitUpdate();
    },
  },
};
</script>

<style lang="less" scoped>

a { color: inherit; }

.input-group {
  flex-direction: column;
}

.dropdown-selector {
  span { font-weight:  500; }
}

.auto-suggest-header {
  margin-bottom: 2rem;
  span {
    font-size: 1rem;
    line-height: 1.4;
  }
}

.auto-suggest {
  padding: 0;
  padding-bottom: .7rem;
  border-bottom: 2px solid @light-navy;
  display: flex;
  flex-wrap: wrap;

  &:focus-within {
    border-color: @teal;
  }

  ::v-deep input:focus {
    border-bottom: none;
  }
}

.auto-suggest-selection {
  padding: .8rem 1rem;
  margin: 0 .8rem .8rem 0;
  font-size: 1.2rem;
  line-height: 1.5;
  background-color: @light-tan;
  font-weight: normal;
  display: inline-block;
  svg { margin-left: .8rem; }
}

::v-deep .autosuggest-results-container {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  width: 100%;
  z-index: 10;
}

::v-deep .autosuggest__results {
  background-color: @white;
  border-radius: 1px;
  border: 1px solid @light-navy;

  ul {
    &:not(:first-child) {
      border-top: solid 1px @light-navy;
    }

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  i {
    font-weight: 500;
    display: block;
    margin-top: 1rem;
  }
}

::v-deep .autosuggest__results-item {
  font-size: 1.2rem;
  line-height: normal;
  text-transform: none;
  line-height: 1.5;
  padding: .4rem;
  margin-left: -.4rem;
  margin-right: -.4rem;

  &.autosuggest__results_item-highlighted {
    background-color: @light-navy;
  }
  &:hover{
    cursor: pointer;
    background-color: @teal;
  }

  div {
    display: flex;
    justify-content: space-between;

    small {
      font-size: 1rem;
      line-height: 1.4;
    }
  }
}

::v-deep .autosuggest__results {
  padding: 2rem;
  padding-top: 0;
}

::v-deep .autosuggest__results-before {
  cursor: default !important;
  color: @teal;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 900;
  line-height: 1.4;

  &:hover {
    background-color: transparent;
  }
}

.remaining {
  letter-spacing: 0.1em;
}

.remaining-urgent {
  color: @orange;
}

.invalid-email {
  color: @orange;

  ::v-deep svg path {
    fill: @orange;
  }
}
</style>
