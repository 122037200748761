var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"row"},[_c('div',{staticClass:"col",on:{"click":_vm.focusInput}},[_c('div',{staticClass:"input-group",class:{'invalid': !_vm.isAudienceValid}},[_c('div',{staticClass:"row auto-suggest-header"},[_c('div',{staticClass:"col d-flex flex-row justify-content-end"},[_c('button',{staticClass:"tertiary",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.clear($event)}}},[_vm._v("clear all")])])]),_c('div',{staticClass:"auto-suggest mb-2"},[_vm._l((_vm.userIds),function(userId,index){return _c('div',{key:userId,staticClass:"auto-suggest-selection",class:{'invalid-email':!_vm.isAudienceValid
              && !_vm.allowExternalDomains
              && _vm.isExternalDomain(_vm.userById(userId).email)}},[(userId !== _vm.loggedInUser.id)?_c('span',[_vm._v(" "+_vm._s(_vm.userNameById(userId) || _vm.userById(userId).email)+" ")]):_c('b',[_vm._v("You")]),(userId !== _vm.loggedInUser.id)?_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.removeUser(index)}}},[_c('icon',{attrs:{"name":"remove","height":"7","width":"7"}})],1):_vm._e()])}),_vm._l((_vm.emails),function(email,index){return _c('div',{key:email,staticClass:"auto-suggest-selection",class:{'invalid-email':!_vm.isAudienceValid
              && !_vm.allowExternalDomains
              && _vm.isExternalDomain(email)}},[_c('span',{staticClass:"auto-suggest-text d-inline-block fs-block"},[_vm._v(_vm._s(email))]),_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.removeEmail(index)}}},[_c('icon',{attrs:{"name":"remove","height":"7","width":"7"}})],1)])}),_c('vue-autosuggest',{ref:"autosuggest-user-input",staticClass:"d-flex flex-grow-1",attrs:{"suggestions":_vm.filteredProfiles,"section-configs":_vm.sectionConfigs,"get-suggestion-value":_vm.getSuggestionValue,"input-props":{
            id: 'autosuggest-user-input',
            placeholder: 'Search by name or email',
          },"component-attr-class-autosuggest-results-container":"autosuggest-results-container"},on:{"input":_vm.onInput,"selected":_vm.onSelectResult,"paste":function($event){$event.preventDefault();return _vm.onPaste($event)}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function (e) { return _vm.parseEmails(e.target.value); })($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var suggestion = ref.suggestion;
return [(suggestion.item.email)?_c('div',[(_vm.userNameById(suggestion.item.userId))?_c('span',{staticClass:"mr-5"},[_vm._v(" "+_vm._s(_vm.userNameById(suggestion.item.userId))+" ")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(suggestion.item.email)+" ")])]):_c('div',{attrs:{"data-tour":"segment-users"}},[_c('span',[_vm._v(_vm._s(suggestion.item.name))]),_c('small',[_vm._v(_vm._s(suggestion.item.users.length)+" Users")])])]}}])},[_c('template',{slot:"after-section-default"},[(!_vm.filteredProfiles[0].data.length)?_c('i',[_vm._v("No matching users found.")]):_vm._e()]),_c('template',{slot:"after-section-segments"},[(!_vm.mySavedSegments.length)?_c('i',[_vm._v("You don't have saved segments.")]):_vm._e()])],2)],2),(!_vm.audienceHasMinRequired)?_c('Error',[_c('span',[_vm._v("The audience requires at least "+_vm._s(_vm.minAudience)+" participants")])]):_vm._e(),(_vm.audienceHasExternalDomains && !this.allowExternalDomains)?_c('Error',[_c('span',[_vm._v("All participants should have the following email domains: "),_vm._l((_vm.domains),function(d,i){return _c('em',{key:'domain-' + i},[_vm._v(" "+_vm._s(i === _vm.domains.length - 1 ? d + '.' : d + ',')+" ")])})],2)]):_vm._e(),(_vm.userLimit)?_c('span',{staticClass:"user-count my-1",staticStyle:{"text-align":"end"}},[_c('b',{staticClass:"remaining",class:{ 'remaining-urgent': _vm.remainingUsers <= 5 }},[_vm._v(" "+_vm._s(_vm.userCount)+" PARTICIPANT"+_vm._s((_vm.userCount) === 1 ? '' : 'S')+" / "+_vm._s(_vm.remainingText)+" ")]),(_vm.remainingUsers <= 0)?_c('p',{staticClass:"user-count"},[_vm._v(" Please email "),_vm._m(0),_vm._v(" if you need additional participants. ")]):_vm._e()]):_vm._e()],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b',[_c('a',{attrs:{"href":"mailto:success@balloon.app","target":"_blank"}},[_vm._v(" success@balloon.app ")])])}]

export { render, staticRenderFns }